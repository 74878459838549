.border {
	border: 1px solid #eaedf1 !important;
	padding: 0.75rem;
	vertical-align: top;
	border: 1px solid #eaedf1;
}
.border-top {
	border-top: 1px solid #eaedf1 !important;
}
.border-end {
	border-right: 1px solid #eaedf1 !important;
}
.border-bottom {
	border-bottom: 1px solid #eaedf1 !important;
}
.border-start {
	border-left: 1px solid #eaedf1 !important;
}
.border-0 {
	border: 0 !important;
}
.border-top-0 {
	border-top: 0 !important;
}
.border-end-0 {
	border-right: 0 !important;
}
.border-bottom-0 {
	border-bottom: 0 !important;
}
.border-start-0 {
	border-left: 0 !important;
}
.border-secondary {
	border-color:#e88555 !important;
}
.border-success {
	border-color: #1bbfa7 !important;
}
.border-info {
	border-color: $azure !important;
}
.border-warning {
	border-color: #ecb403 !important;
}
.border-danger {
	border-color: #f82649 !important;
}
.border-light {
	border-color: #f8f9fa !important;
}
.border-transparent {
	border-color: #656a71!important;
}
.border-dark {
	border-color: $dark !important;
}
.border-white {
	border-color: $white !important;
}
.br-100 {
	border-radius: 100% !important;
}
.br-7 {
	border-radius: 7px;
}
.br-5 {
	border-radius: 5px;
}
.br-tl-7 {
	border-top-left-radius: 7px !important;
}
.br-bl-7 {
	border-bottom-left-radius: 7px !important;
}
.br-tr-7 {
	border-top-right-radius: 7px !important;
}
.br-br-7 {
	border-bottom-right-radius: 7px !important;
}
.br-tl-0 {
	border-top-left-radius: 0px !important;
}
.br-bl-0 {
	border-bottom-left-radius: 0px !important;
}
.br-tr-0 {
	border-top-right-radius: 0px !important;
}
.br-br-0 {
	border-bottom-right-radius: 0px !important;
}
.br-0 {
	border-radius: 0 !important;
}
.noborder {
	border-radius: 0;
}
.brround {
	border-radius: 50% !important; 
}
.bradius {
	border-radius: 25%;
}
.border-danger {
	border: 2px solid ;
}
.border-end-1 {
	border-right: 1px solid #d5dce3;
}
.rounded {
	border-radius: 50% !important;
}
.rounded-top {
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}
.rounded-end {
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
}
.rounded-bottom {
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.rounded-start {
	border-top-left-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.rounded-circle {
	border-radius: 50% !important;
}
.rounded-0 {
	border-radius: 0 !important;
}
.clearfix::after {
	display: block;
	clear: both;
	content: "";
}
.border-dotted{
	border:2px dotted;
}
.border-solid{
	border:2px solid;
}