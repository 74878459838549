/*-----Badges-----*/

.btn .badge {
	position: relative;
	top: -1px;
}
.badge {
	display: inline-block;
	padding: 3px 5px 4px;
	font-size: 100%;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 3px;
	font-size: 12px;
	font-weight: 500;
}
.rounded-pill {
	border-radius: 50px;
}
.badgetext {
	float: right;
}
.btn-custom {
	background: #f1f1f9;
	color:#76839a;
}