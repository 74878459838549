
/*--------- Buttons--------------*/

.btn {
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 0.9375rem;
	line-height: 1.84615385;
	border-radius: 5px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	&:hover {
		text-decoration: none;
	}
	&:focus {
		text-decoration: none;
		outline: none !important;
	}
	&.focus {
		outline: 0;
		box-shadow: none;
	}
	&.disabled, &:disabled {
		opacity: 0.65;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
		&:active, &.active {
			background-image: none;
		}
	}
}
@media screen and (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}
a.btn.disabled, fieldset:disabled a.btn {
	pointer-events: none;
}
.btn-secondary {
	color: $white;
	background: $secondary !important;
	box-shadow: 0 5px 10px rgba(230, 104, 43, 0.3);
	&:hover {
		color: $white;
		background-color: #e6682b;
		border-color: #e6682b;
	}
	&:focus, &:focus {
		box-shadow: 0 0 0 2px rgba(230, 104, 43, 0.9);
		border-color: $secondary;
	}
	&:disabled, &:disabled {
		color: $white;
		background-color: #e6682b;
		border-color: #e6682b;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #e6682b;
			border-color: #e6682b;
		}
	}
}
.btn-secondary.disabled, .btn-secondary:disabled{
	border:transparent;
}
.show>.btn-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}
.btn-secondary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(230, 104, 43, 0.9);
		border:1px solid $secondary;
	}
}
.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(230, 104, 43, 0.9);
	order:1px solid $secondary;
}
.btn-success {
	color: $white;
	background: #13bfa6 !important;
	border-color: #35b9a6;
	box-shadow: 0 5px 10px rgba(30, 148, 131, 0.3);
	&:hover {
		color: $white;
		background-color: #13bfa6;
		border-color: #13bfa6;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgb(19, 191, 166);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #13bfa6;
		border-color: #13bfa6;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #13bfa6;
			border-color: #13bfa6;
		}
	}
}
.show>.btn-success.dropdown-toggle {
	color: $white;
	background-color: #13bfa6;
	border-color: #32cab4;
}
.btn-success:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(19, 191, 166, 0.9);
	}
}
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(19, 191, 166, 0.9);
}
.btn-info {
	color: $white;
	background: $info;
	border-color: #3988e8;
	box-shadow: 0 5px 10px rgba(7, 116, 248, 0.3);
	&:hover {
		color: $white;
		background-color: #086de6;
		border-color: #086de6;
	}
	&:focus, &.focus {
		color: $white;
		background: $info;
		border-color: $info;
		box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.9);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $info;
		border-color: $info;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $info;
			border-color: $info;
		}
	}
}
.show>.btn-info.dropdown-toggle {
	color: $white;
	background-color: $info;
	border-color: $info;
}
.btn-info:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		color: $white;
		background: $info;
		border-color: $info;
		box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.9);
	}
}
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.9);
}
.btn-warning {
	color: $white !important;
	background: $warning !important;
	border-color: #e5a623;
	box-shadow: 0 5px 10px rgba(247, 183, 49, 0.3);
	&:hover {
		color: $white;
		background-color: #dba12a;
		border-color: #dda32b;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(229, 175, 4, 0.9);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #faad3b;
		border-color: #e5a623;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #cc9547;
			border-color: #e5a623;
		}
	}
}
.show>.btn-warning.dropdown-toggle {
	color: $white;
	background-color: #cc9547;
	border-color: #e5a623;
}
.btn-warning:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(237, 156, 76, 0.9);
	}
}
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(237, 156, 76, 0.9);
}
.btn-danger {
	color: $white;
	background: $danger !important;
	border-color: #e62a45;
	box-shadow: 0 5px 10px rgba(237, 49, 76, 0.3);
	&:hover {
		color: $white;
		background-color: #dd2540;
		border-color: #df2540;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(245, 51, 79, 0.9);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #f5334f;
		border-color: #ed314c;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #f5334f;
			border-color: #ed314c;
		}
	}
}
.show>.btn-danger.dropdown-toggle {
	color: $white;
	background-color: #f5334f;
	border-color: #ed314c;
}
.btn-danger:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(245, 51, 79, 0.9);
	}
}
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(245, 51, 79, 0.9);
}
.btn-default {
	color: #292828;
	background: #f6f4fb;
	border-color: #b9b5b5;
	&:hover {
		color: #292828;
		background-color: #d5d4d6;
		border-color: #a8a6a9;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(240, 239, 241, 0.9);
	}
	&.disabled, &:disabled {
		color: #292828;
		background-color: #e5e4e6;
		border-color: #a8a6a9;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: #292828;
			background-color: #eceeef;
			border-color: #c8cacc;
		}
	}
}
.show>.btn-default.dropdown-toggle {
	color: #292828;
	background-color: #eceeef;
	border-color: #c8cacc;
}
.btn-default:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(185, 182, 187, 0.9);
	}
}
.show>.btn-default.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(185, 182, 187, 0.9);
}
/*-- light-buttons--*/

.btn-secondary-light {
	color: $secondary;
	background: #fdd6c3 !important;
	border-color: #fdd6c3;
	box-shadow: 0 5px 10px rgba(235, 111, 51, 0.3);
	&:hover {
		color: $white;
		background-color: $secondary !important;
		border-color: $secondary;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(235, 111, 51, 0.9);
		border: 1px solid $secondary;
	}
}
.btn-success-light {
	color: #13bfa6;
	background: #a6efe4 !important;
	border-color: #a6efe4;
	box-shadow: 0 5px 10px rgba(19, 191, 166, 0.3);
	&:hover {
		color: $white;
		background-color: #13bfa6 !important;
		border-color: #13bfa6;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgb(19, 191, 166);
	}
}
.btn-info-light {
	color: $info;
	background: #d0e2f7;
	border-color: #d0e2f7;
	box-shadow: 0 5px 10px rgba(7, 116, 248, 0.3);
	&:hover {
		color: $white;
		background-color: $info;
		border-color: $info;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.9);
	}
}
.btn-warning-light {
	color: #e0a01b;
	background: #f8e8c6 !important;
	border-color: #f8e8c6;
	box-shadow: 0 5px 10px rgba(247, 183, 49, 0.3);
	&:hover {
		color: $white;
		background-color: $warning !important;
		border-color: #e5a623;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(229, 175, 4, 0.9);
	}
}
.btn-danger-light {
	color: #f5334f;
	background: #f8ccd3 !important;
	border-color: #f8ccd3;
	box-shadow: 0 5px 10px rgba(237, 49, 76, 0.3);
	&:hover {
		color: $white;
		background-color: $danger !important;
		border-color: #ed314c;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(245, 51, 79, 0.9);
	}
}
.btn-default-light {
	color: #292828;
	background: #f6f4fb;
	border-color: #f6f4fb;
	box-shadow: 0 5px 10px rgba(227, 228, 237, 0.3);
	&:hover {
		color: #292828;
		background-color: #ebf3fa !important;
		border-color: #dde8f2;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgb(246, 249, 252);
	}
}
/*--gradient-buttons--*/

.btn-secondary-gradient {
	color: $white;
	background: linear-gradient(to bottom right, #ce4300 0%, #fb854c 100%) !important;
	border-color: #eb6f33;
	&:hover {
		color: $white;
		background-color: #eb6f33;
		border-color: #f66b4e;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(235, 111, 51, 0.9);
		border:1px solid $secondary;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #eb6f33;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #eb6f33;
			border-color: #f66b4e;
		}
	}
}
.show>.btn-secondary-gradient.dropdown-toggle {
	color: $white;
	background-color: #eb6f33;
	border-color: #f66b4e;
}
.btn-secondary-gradient:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(235, 111, 51, 0.9);
	}
}
.show>.btn-secondary-gradient.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(235, 111, 51, 0.9);
}
.btn-success-gradient {
	color: $white;
	background:$success-gradient !important;
	border-color: $green;
	&:hover {
		color: $white;
		background-color: #4b9400;
		border-color: #448700;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.9);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $green;
		border-color: $green;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #448700;
			border-color: #3e7a00;
		}
	}
}
.show>.btn-success-gradient.dropdown-toggle {
	color: $white;
	background-color: #448700;
	border-color: #3e7a00;
}
.btn-success-gradient:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.9);
	}
}
.show>.btn-success-gradient.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.9);
}
.btn-info-gradient {
	color: $white;
	background: $info-gradient !important;
	border-color: #07cedc;
	&:hover {
		color: $white;
		background-color: #219af0;
		border-color: #1594ef;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.9);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $azure;
		border-color: #07cedc;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #3979a7;
			border-color: #07cedc;
		}
	}
}
.show>.btn-info-gradient.dropdown-toggle {
	color: $white;
	background-color: #3979a7;
	border-color: #07cedc;
}
.btn-info-gradient:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.9);
	}
}
.show>.btn-info-gradient.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.9);
}
.btn-warning-gradient {
	color: $white;
	background: $warning-gradient !important;
	border-color: $yellow;
	&:hover {
		color: $white;
		background-color: #faad3b;
		border-color: #d4952d;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(229, 175, 4, 0.9);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #faad3b;
		border-color: $yellow;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #cc9547;
			border-color: $yellow;
		}
	}
}
.show>.btn-warning-gradient.dropdown-toggle {
	color: $white;
	background-color: #cc9547;
	border-color: $yellow;
}
.btn-warning-gradient:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(237, 156, 76, 0.9);
	}
}
.show>.btn-warning-gradient.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(237, 156, 76, 0.9);
}
.btn-warning-gradient-gradient {
	background: linear-gradient(to bottom right, #fbc434 0%, #f66b4e 100%);
	&:focus {
		box-shadow: 0 0 0 2px rgba(249, 165, 61, 0.4);
	}
}
.btn-warning-gradient {
	&.focus {
		box-shadow: 0 0 0 2px rgba(249, 165, 61, 0.4);
	}
	&:hover {
		color: $white;
		box-shadow: 0 3px 10px rgba(240, 138, 46, 0.5);
	}
}
.btn-danger-gradient {
	color: $white;
	background-image: $danger-gradient !important;
	border-color: #e3325a;
	&:hover {
		color: $white;
		background-color: #FF4B2B;
		border-color: #e3325a;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(255, 75, 43, 0.9);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #FF4B2B;
		border-color: #e3325a;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #FF4B2B;
			border-color: #e3325a;
		}
	}
}
.show>.btn-danger-gradient.dropdown-toggle {
	color: $white;
	background-color: #FF4B2B;
	border-color: #e3325a;
}
.btn-danger-gradient:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(255, 75, 43, 0.9);
	}
}
.show>.btn-danger-gradient.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 75, 43, 0.9);
}
.btn-default {
	color: #292828;
	background: #f6f4fb;
	border-color: #f0f0f4;
	box-shadow: 0 5px 10px rgba(227, 228, 237, 0.3);
	&:hover {
		color: #292828;
		background-color: #e9e5f1;
		border-color: #f0f0f4;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgb(229, 240, 250);
	}
	&.disabled, &:disabled {
		color: #292828;
		background-color: #e9e5f1;
		border-color: #f0f0f4;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: #292828;
			background-color: #e9e5f1;
			border-color: #f0f0f4;
		}
	}
}
.show>.btn-default.dropdown-toggle {
	color: #292828;
	background-color: #e9e5f1;
	border-color: #f0f0f4;
}
.btn-default:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(229, 240, 250, 0.9);
	}
}
.show>.btn-default.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(229, 240, 250, 0.9);
}
.btn-purple-gradient {
	background: linear-gradient(to bottom right, $purple 0%, #647DEE 100%) !important;
	color: $white !important;
}
.btn-light {
	color: #495057;
	background-color: #f5f6fb;
	border-color: #f0f0f4;
	box-shadow: 0 5px 10px rgba(227, 228, 237, 0.3);
	&:hover {
		color: #495057;
		background-color: #eeebf3;
		border-color: #eeebf3;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
	&.disabled, &:disabled {
		color: #495057;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: #495057;
			background-color: #f6f3f9;
			border-color: #f0f0f4;
		}
	}
}
.show>.btn-light.dropdown-toggle {
	color: #495057;
	background-color: #f6f3f9;
	border-color: #f0f0f4;
}
.btn-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
}
.show>.btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-dark {
	color: $white;
	background-color: $dark;
	border-color: $dark;
	&:hover {
		color: $white;
		background-color: #23272b;
		border-color: #1d2124;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #1d2124;
			border-color: #171a1d;
		}
	}
}
.show>.btn-dark.dropdown-toggle {
	color: $white;
	background-color: #1d2124;
	border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
}
.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}
.btn-outline-default {
	color: #292828;
	background: transparent;
	background-image: none;
	border-color: #f0f0f4;
	&:hover {
		color: #292828;
		background: #f6f4fb;
		border-color: #f0f0f4;
		box-shadow: 0 5px 15px rgba(246, 249, 252, 0.5) !important;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(246, 249, 252, 0.1);
	}
	&.disabled, &:disabled {
		color: #292828;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: #292828;
			background-color: #f6f4fb;
			border-color: #f0f0f4;
		}
	}
}
.show>.btn-outline-default.dropdown-toggle {
	color: #292828;
	background-color: #f6f4fb;
	border-color: #f0f0f4;
}
.btn-outline-secondary {
	color: $secondary;
	background-color: transparent;
	background-image: none;
	border-color: $secondary;
	&:hover {
		color: $white;
		background-color: $secondary;
		border-color: #e6682b;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(235, 111, 51, 0.9);
	}
	&.disabled, &:disabled {
		color: #e6682b;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $secondary;
			border-color: #e6682b;
		}
	}
}
.show>.btn-outline-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: #e6682b;
}
.btn-outline-success {
	color: #13bfa6;
	background-color: transparent;
	background-image: none;
	border-color: #13bfa6;
	&:hover {
		color: $white;
		background-color: #13bfa6;
		border-color: #13bfa6;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(19, 191, 166, 0.1);
	}
	&.disabled, &:disabled {
		color: #13bfa6;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #13bfa6;
			border-color: #13bfa6;
		}
	}
}
.show>.btn-outline-success.dropdown-toggle {
	color: $white;
	background-color: #13bfa6;
	border-color: #13bfa6;
}
.btn-outline-success:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(19, 191, 166, 0.1);
	}
}
.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(19, 191, 166, 0.1);
}
.btn-outline-info {
	color: $azure;
	background-color: transparent;
	background-image: none;
	border-color: $azure;
	&:hover {
		color: $white;
		background-color: $azure;
		border-color: $azure;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.1);
	}
	&.disabled, &:disabled {
		color: $azure;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $azure;
			border-color: $azure;
		}
	}
}
.show>.btn-outline-info.dropdown-toggle {
	color: $white;
	background-color: $azure;
	border-color: $azure;
}
.btn-outline-info:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.1);
	}
}
.show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.1);
}
.btn-outline-warning {
	color: #ecb403;
	background-color: transparent;
	background-image: none;
	border-color: #ecb403;
	&:hover {
		color: $white;
		background-color: #ecb403;
		border-color: #ecb403;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
	}
	&.disabled, &:disabled {
		color: #ecb403;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #ecb403;
			border-color: #ecb403;
		}
	}
}
.show>.btn-outline-warning.dropdown-toggle {
	color: $white;
	background-color: #ecb403;
	border-color: #ecb403;
}
.btn-outline-warning:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
	}
}
.show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
}
.btn-outline-danger {
	color: #c21a1a;
	background-color: transparent;
	background-image: none;
	border-color: #f12c2c;
	&:hover {
		color: $white;
		background-color: #f12c2c;
		border-color: #f12c2c;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
	}
	&.disabled, &:disabled {
		color: #c21a1a;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #c21a1a;
			border-color: #c21a1a;
		}
	}
}
.show>.btn-outline-danger.dropdown-toggle {
	color: $white;
	background-color: #c21a1a;
	border-color: #c21a1a;
}
.btn-outline-danger:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
	}
}
.show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
}
.btn-outline-light {
	color: #f8f9fa;
	background-color: transparent;
	background-image: none;
	border-color: #f8f9fa;
	&:hover {
		color: #495057;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
	&.disabled, &:disabled {
		color: #f8f9fa;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: #495057;
			background-color: #f8f9fa;
			border-color: #f8f9fa;
		}
	}
}
.show>.btn-outline-light.dropdown-toggle {
	color: #495057;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
}
.show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-outline-dark {
	color: $dark;
	background-color: transparent;
	background-image: none;
	border-color: $dark;
	&:hover {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
	&.disabled, &:disabled {
		color: $dark;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $dark;
			border-color: $dark;
		}
	}
}
.show>.btn-outline-dark.dropdown-toggle {
	color: $white;
	background-color: $dark;
	border-color: $dark;
}
.btn-outline-dark:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
}
.show>.btn-outline-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}
.btn-link {
	font-weight: 400;
	background-color: transparent;
	box-shadow: 0 5px 10px rgba(227, 228, 237, 0.3);
	&:hover {
		text-decoration: underline;
		background-color: transparent;
		border-color: transparent;
	}
	&:focus, &.focus {
		text-decoration: underline;
		border-color: transparent;
		box-shadow: none;
	}
	&:disabled, &.disabled {
		color: #868e96;
		pointer-events: none;
	}
}
.btn-lg, .btn-group-lg>.btn {
	padding: 0.5rem 1rem;
	font-size: 1.125rem;
	line-height: 1.625;
}
.btn-sm, .btn-group-sm>.btn {
	padding: 0.35rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1;
	border-radius: 3px;
}
.btn-block {
	display: block;
	width: 100%;
	+.btn-block {
		margin-top: 0.5rem;
	}
}
.btn-facebook {
	background: linear-gradient(to right bottom, #3d6cbf 0%, #1e3c72 100%);
}
input {
	&[type="submit"].btn-block, &[type="reset"].btn-block, &[type="button"].btn-block {
		width: 100%;
	}
}
/**Btn-group**/

.btn-group, .btn-group-vertical {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
}
.btn-group>.btn, .btn-group-vertical>.btn {
	position: relative;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
}
.btn-group>.btn:hover, .btn-group-vertical>.btn:hover {
	z-index: 1;
}
.btn-group>.btn {
	&:focus, &:active, &.active {
		z-index: 1;
	}
}
.btn-group-vertical>.btn {
	&:focus, &:active, &.active {
		z-index: 1;
	}
}
.btn-group {
	.btn+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
	.btn-group+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
}
.btn-group-vertical {
	.btn+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
	.btn-group+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
}
.btn-toolbar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
	.input-group {
		width: auto;
	}
}
.btn-group> {
	.btn {
		&:first-child {
			margin-left: 0;
		}
		&:not(:last-child):not(.dropdown-toggle) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	.btn-group:not(:last-child)>.btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.btn:not(:first-child), .btn-group:not(:first-child)>.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
	&::after {
		margin-left: 0;
	}
}
.dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
	margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}
.btn-sm+.dropdown-toggle-split, .btn-group-sm>.btn+.dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
}
.btn-lg+.dropdown-toggle-split, .btn-group-lg>.btn+.dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.btn-group-vertical {
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
	.btn, .btn-group {
		width: 100%;
	}
	> {
		.btn+ {
			.btn, .btn-group {
				margin-top: -1px;
				margin-left: 0;
			}
		}
		.btn-group+ {
			.btn, .btn-group {
				margin-top: -1px;
				margin-left: 0;
			}
		}
		.btn:not(:last-child):not(.dropdown-toggle), .btn-group:not(:last-child)>.btn {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		.btn:not(:first-child), .btn-group:not(:first-child)>.btn {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}
.btn-group-toggle> {
	.btn, .btn-group>.btn {
		margin-bottom: 0;
	}
	.btn input {
		&[type="radio"], &[type="checkbox"] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
	}
	.btn-group>.btn input {
		&[type="radio"], &[type="checkbox"] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
	}
}
/*----- Btn styles------*/

.btn {
	cursor: pointer;
	font-weight: 400;
	letter-spacing: .03em;
	font-size: 0.8125rem;
	min-width: 2.375rem;
}
.btn-icon {
	padding-left: .5rem;
	padding-right: .5rem;
	text-align: center;
}
/*.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px #1643A3;
}*/

.btn-pill {
	border-radius: 10rem;
	padding-left: 1.5em;
	padding-right: 1.5em;
}
.btn-radius {
	border-radius: 3px;
}
.btn-facebook {
	color: $white;
	background-color: #3b5998;
	border-color: #3b5998;
	&:hover {
		color: $white;
		background-color: #30497c;
		border-color: #2d4373;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #3b5998;
		border-color: #3b5998;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #2d4373;
			border-color: #293e6a;
		}
	}
}
.show>.btn-facebook.dropdown-toggle {
	color: $white;
	background-color: #2d4373;
	border-color: #293e6a;
}
.btn-facebook:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
	}
}
.show>.btn-facebook.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
}
.btn-twitter {
	color: $white;
	background-color: #1da1f2;
	border-color: #1da1f2;
	&:hover {
		color: $white;
		background-color: #0d8ddc;
		border-color: #0c85d0;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #1da1f2;
		border-color: #1da1f2;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #0c85d0;
			border-color: #0b7ec4;
		}
	}
}
.show>.btn-twitter.dropdown-toggle {
	color: $white;
	background-color: #0c85d0;
	border-color: #0b7ec4;
}
.btn-twitter:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
	}
}
.show>.btn-twitter.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
}
.btn-google {
	color: $white;
	background-color: #dc4e41;
	border-color: #dc4e41;
	&:hover {
		color: $white;
		background-color: #d03526;
		border-color: #c63224;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #dc4e41;
		border-color: #dc4e41;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #c63224;
			border-color: #bb2f22;
		}
	}
}
.show>.btn-google.dropdown-toggle {
	color: $white;
	background-color: #c63224;
	border-color: #bb2f22;
}
.btn-google:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
	}
}
.show>.btn-google.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
}
.btn-youtube {
	color: $white;
	background-color: #f00;
	border-color: #f00;
	&:hover {
		color: $white;
		background-color: #d90000;
		border-color: #cc0000;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #f00;
		border-color: #f00;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #cc0000;
			border-color: #bf0000;
		}
	}
}
.show>.btn-youtube.dropdown-toggle {
	color: $white;
	background-color: #cc0000;
	border-color: #bf0000;
}
.btn-youtube:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
	}
}
.show>.btn-youtube.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
}
.btn-vimeo {
	color: $white;
	background-color: #1ab7ea;
	border-color: #1ab7ea;
	&:hover {
		color: $white;
		background-color: #139ecb;
		border-color: #1295bf;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #1ab7ea;
		border-color: #1ab7ea;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #1295bf;
			border-color: #108cb4;
		}
	}
}
.show>.btn-vimeo.dropdown-toggle {
	color: $white;
	background-color: #1295bf;
	border-color: #108cb4;
}
.btn-vimeo:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
	}
}
.show>.btn-vimeo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
}
.btn-dribbble {
	color: $white;
	background-color: #ea4c89;
	border-color: #ea4c89;
	&:hover {
		color: $white;
		background-color: #e62a72;
		border-color: #e51e6b;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #ea4c89;
		border-color: #ea4c89;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #e51e6b;
			border-color: #dc1a65;
		}
	}
}
.show>.btn-dribbble.dropdown-toggle {
	color: $white;
	background-color: #e51e6b;
	border-color: #dc1a65;
}
.btn-dribbble:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
	}
}
.show>.btn-dribbble.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
}
.btn-github {
	color: $white;
	background-color: #181717;
	border-color: #181717;
	&:hover {
		color: $white;
		background-color: #040404;
		border-color: black;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #181717;
		border-color: #181717;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: black;
			border-color: black;
		}
	}
}
.show>.btn-github.dropdown-toggle {
	color: $white;
	background-color: black;
	border-color: black;
}
.btn-github:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
	}
}
.show>.btn-github.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
}
.btn-instagram {
	color: $white;
	background-color: #e4405f;
	border-color: #e4405f;
	&:hover {
		color: $white;
		background-color: #de1f44;
		border-color: #d31e40;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #e4405f;
		border-color: #e4405f;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #d31e40;
			border-color: #c81c3d;
		}
	}
}
.show>.btn-instagram.dropdown-toggle {
	color: $white;
	background-color: #d31e40;
	border-color: #c81c3d;
}
.btn-instagram:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
	}
}
.show>.btn-instagram.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
}
.btn-pinterest {
	color: $white;
	background-color: #bd081c;
	border-color: #bd081c;
	&:hover {
		color: $white;
		background-color: #980617;
		border-color: #8c0615;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #bd081c;
		border-color: #bd081c;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #8c0615;
			border-color: #800513;
		}
	}
}
.show>.btn-pinterest.dropdown-toggle {
	color: $white;
	background-color: #8c0615;
	border-color: #800513;
}
.btn-pinterest:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
	}
}
.show>.btn-pinterest.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
}
.btn-vk {
	color: $white;
	background-color: #6383a8;
	border-color: #6383a8;
	&:hover {
		color: $white;
		background-color: #527093;
		border-color: #4d6a8b;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #6383a8;
		border-color: #6383a8;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #4d6a8b;
			border-color: #496482;
		}
	}
}
.show>.btn-vk.dropdown-toggle {
	color: $white;
	background-color: #4d6a8b;
	border-color: #496482;
}
.btn-vk:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
	}
}
.show>.btn-vk.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
}
.btn-rss {
	color: $white;
	background-color: #ffa500;
	border-color: #ffa500;
	&:hover {
		color: $white;
		background-color: #d98c00;
		border-color: #cc8400;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #ffa500;
		border-color: #ffa500;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #cc8400;
			border-color: #bf7c00;
		}
	}
}
.show>.btn-rss.dropdown-toggle {
	color: $white;
	background-color: #cc8400;
	border-color: #bf7c00;
}
.btn-rss:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
	}
}
.show>.btn-rss.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}
.btn-flickr {
	color: $white;
	background-color: #0063dc;
	border-color: #0063dc;
	&:hover {
		color: $white;
		background-color: #0052b6;
		border-color: #004ca9;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #0063dc;
		border-color: #0063dc;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #004ca9;
			border-color: #00469c;
		}
	}
}
.show>.btn-flickr.dropdown-toggle {
	color: $white;
	background-color: #004ca9;
	border-color: #00469c;
}
.btn-flickr:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
	}
}
.show>.btn-flickr.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
}
.btn-bitbucket {
	color: $white;
	background-color: #0052cc;
	border-color: #0052cc;
	&:hover {
		color: $white;
		background-color: #0043a6;
		border-color: #003e99;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #0052cc;
		border-color: #0052cc;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #003e99;
			border-color: #00388c;
		}
	}
}
.show>.btn-bitbucket.dropdown-toggle {
	color: $white;
	background-color: #003e99;
	border-color: #00388c;
}
.btn-bitbucket:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
	}
}
.show>.btn-bitbucket.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
}
.btn-blue {
	color: $white;
	background-color: #467fcf;
	border-color: #467fcf;
	box-shadow: 0 5px 10px rgba(70, 127, 207, 0.3);
	&:hover {
		color: $white;
		background-color: #316cbe;
		border-color: #1f5bb1;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #467fcf;
		border-color: #467fcf;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #1f5bb1;
			border-color: #2c60a9;
		}
	}
}
.show>.btn-blue.dropdown-toggle {
	color: $white;
	background-color: #1f5bb1;
	border-color: #2c60a9;
}
.btn-blue:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
	}
}
.show>.btn-blue.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
}
.btn-indigo {
	color: $white;
	background-color: $indigo;
	border-color: $indigo;
	box-shadow: 0 5px 10px rgba(101, 116, 205, 0.3);
	&:hover {
		color: $white;
		background-color: #485ac4;
		border-color: #3f51c1;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $indigo;
		border-color: $indigo;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #3f51c1;
			border-color: #3b4db7;
		}
	}
}
.show>.btn-indigo.dropdown-toggle {
	color: $white;
	background-color: #3f51c1;
	border-color: #3b4db7;
}
.btn-indigo:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
	}
}
.show>.btn-indigo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
}
.btn-purple {
	color: $white;
	background: #661fd6 !important;
	border-color: #661fd6;
	box-shadow: 0 5px 10px rgba(102, 31, 214, 0.3);
	&:hover {
		color: $white;
		background-color: #923ce6;
		border-color: #8c31e4;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #867efc;
		border-color: #867efc;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #8c31e4;
			border-color: #8526e3;
		}
	}
}
.show>.btn-purple.dropdown-toggle {
	color: $white;
	background-color: #8c31e4;
	border-color: #8526e3;
}
.btn-purple:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
	}
}
.show>.btn-purple.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
}
.btn-pink {
	color: $white;
	background-color: #ec82ef;
	border-color: #ec82ef;
	box-shadow: 0 5px 10px rgba(236, 130, 239, 0.3);
	&:hover {
		color: $white;
		background-color: #f44982;
		border-color: #f33d7a;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #ec82ef;
		border-color: #ec82ef;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #f33d7a;
			border-color: #f23172;
		}
	}
}
.show>.btn-pink.dropdown-toggle {
	color: $white;
	background-color: #f33d7a;
	border-color: #f23172;
}
.btn-pink:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
	}
}
.show>.btn-pink.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
}
.btn-red {
	color: $white;
	background-color: #c21a1a;
	border-color: #c21a1a;
	box-shadow: 0 5px 10px rgba(194, 26, 26, 0.3);
	&:hover {
		color: $white;
		background-color: #ac1b1a;
		border-color: #a11918;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #c21a1a;
		border-color: #c21a1a;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #a11918;
			border-color: #961717;
		}
	}
}
.show>.btn-red.dropdown-toggle {
	color: $white;
	background-color: #a11918;
	border-color: #961717;
}
.btn-red:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
	}
}
.show>.btn-red.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
}
.btn-orange {
	color: $white;
	background-color: $orange;
	border-color: $orange;
	box-shadow: 0 5px 10px rgba(252, 115, 3, 0.3);
	&:hover {
		color: $white;
		background-color: #fd811e;
		border-color: #fc7a12;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $orange;
		border-color: $orange;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #fc7a12;
			border-color: #fc7305;
		}
	}
}
.show>.btn-orange.dropdown-toggle {
	color: $white;
	background-color: #fc7a12;
	border-color: #fc7305;
}
.btn-orange:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
	}
}
.show>.btn-orange.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
}
.btn-yellow {
	color: $white;
	background-color: #ecb403;
	border-color: #ecb403;
	box-shadow: 0 5px 10px rgba(236, 180, 3, 0.3);
	&:hover {
		color: $white;
		background-color: #cea70c;
		border-color: #c29d0b;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #ecb403;
		border-color: #ecb403;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #c29d0b;
			border-color: #b6940b;
		}
	}
}
.show>.btn-yellow.dropdown-toggle {
	color: $white;
	background-color: #c29d0b;
	border-color: #b6940b;
}
.btn-yellow:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
	}
}
.show>.btn-yellow.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
}
.btn-green {
	color: $white;
	background-color: $green;
	border-color: $green;
	box-shadow: 0 5px 10px rgba(78, 204, 72, 0.3);
	&:hover {
		color: $white;
		background-color: #4b9400;
		border-color: #448700;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $green;
		border-color: $green;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #448700;
			border-color: #3e7a00;
		}
	}
}
.show>.btn-green.dropdown-toggle {
	color: $white;
	background-color: #448700;
	border-color: #3e7a00;
}
.btn-green:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
	}
}
.show>.btn-green.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
}
.btn-teal {
	color: $white;
	background-color: #2bcbba;
	border-color: #2bcbba;
	box-shadow: 0 5px 10px rgba(43, 203, 186, 0.3);
	&:hover {
		color: $white;
		background-color: #24ab9d;
		border-color: #22a193;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #2bcbba;
		border-color: #2bcbba;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #22a193;
			border-color: #20968a;
		}
	}
}
.show>.btn-teal.dropdown-toggle {
	color: $white;
	background-color: #22a193;
	border-color: #20968a;
}
.btn-teal:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
	}
}
.show>.btn-teal.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
}
.btn-cyan {
	color: $white;
	background-color: #17a2b8;
	border-color: #17a2b8;
	box-shadow: 0 5px 10px rgba(23, 162, 184, 0.3);
	&:hover {
		color: $white;
		background-color: #138496;
		border-color: #117a8b;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #17a2b8;
		border-color: #17a2b8;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #117a8b;
			border-color: #10707f;
		}
	}
}
.show>.btn-cyan.dropdown-toggle {
	color: $white;
	background-color: #117a8b;
	border-color: #10707f;
}
.btn-cyan:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
	}
}
.show>.btn-cyan.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
}
.btn-white {
	color: #495057;
	background-color: $white;
	border: 1px solid #e9ebfa;
	&:hover {
		color: #495057;
		background-color: #ececec;
		border-color: #e6e5e5;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px $white-5;
	}
	&.disabled, &:disabled {
		color: #495057;
		background-color: $white;
		border-color: $white;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: #495057;
			background-color: #e6e5e5;
			border-color: #dfdfdf;
		}
	}
}
.show>.btn-white.dropdown-toggle {
	color: #495057;
	background-color: #e6e5e5;
	border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px $white-5;
	}
}
.show>.btn-white.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $white-5;
}
.btn-gray {
	color: $white;
	background-color: #868e96;
	border-color: #868e96;
	box-shadow: 0 5px 10px rgba(134, 142, 150, 0.3);
	&:hover {
		color: $white;
		background-color: #727b84;
		border-color: #6c757d;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #868e96;
		border-color: #868e96;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #6c757d;
			border-color: #666e76;
		}
	}
}
.show>.btn-gray.dropdown-toggle {
	color: $white;
	background-color: #6c757d;
	border-color: #666e76;
}
.btn-gray:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}
}
.show>.btn-gray.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
}
.btn-gray-dark {
	color: $white;
	background-color: $dark;
	border-color: $dark;
	box-shadow: 0 5px 10px rgba(52, 58, 64, 0.3);
	&:hover {
		color: $white;
		background-color: #23272b;
		border-color: #1d2124;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #1d2124;
			border-color: #171a1d;
		}
	}
}
.show>.btn-gray-dark.dropdown-toggle {
	color: $white;
	background-color: #1d2124;
	border-color: #171a1d;
}
.btn-gray-dark:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
	}
}
.show>.btn-gray-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
}
.btn-azure {
	color: $white;
	background-color: $azure;
	border-color: $azure;
	box-shadow: 0 5px 10px rgba(69, 170, 242, 0.3);
	&:hover {
		color: $white;
		background-color: #219af0;
		border-color: #1594ef;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $azure;
		border-color: $azure;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #1594ef;
			border-color: #108ee7;
		}
	}
}
.show>.btn-azure.dropdown-toggle {
	color: $white;
	background-color: #1594ef;
	border-color: #108ee7;
}
.btn-azure:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.5);
	}
}
.show>.btn-azure.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(7, 116, 248, 0.5);
}
.btn-lime {
	color: $white;
	background-color: $lime;
	border-color: $lime;
	box-shadow: 0 5px 10px rgba(123, 210, 53, 0.3);
	&:hover {
		color: $white;
		background-color: #69b829;
		border-color: #63ad27;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $lime;
		border-color: $lime;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #63ad27;
			border-color: #5da324;
		}
	}
}
.show>.btn-lime.dropdown-toggle {
	color: $white;
	background-color: #63ad27;
	border-color: #5da324;
}
.btn-lime:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
	}
}
.show>.btn-lime.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
}
.btn-option {
	background: transparent;
	color: #9aa0ac;
	&:hover {
		color: #6e7687;
	}
	&:focus {
		box-shadow: none;
		color: #6e7687;
	}
}
.btn-sm, .btn-group-sm>.btn {
	font-size: 0.65rem;
	min-width: 1.625rem;
}
.btn-lg, .btn-group-lg>.btn {
	font-size: 1rem;
	min-width: 2.75rem;
	font-weight: 400;
}
.btn-list {
	margin-bottom: -.5rem;
	font-size: 0;
	> {
		.btn, .dropdown {
			margin-bottom: .5rem;
		}
		.btn:not(:last-child), .dropdown:not(:last-child) {
			margin-right: .5rem;
		}
	}
}
.btn-loading {
	color: transparent !important;
	pointer-events: none;
	position: relative;
	&:after {
		content: '';
		-webkit-animation: loader 500ms infinite linear;
		animation: loader 500ms infinite linear;
		border: 2px solid $white;
		border-radius: 50%;
		border-right-color: transparent !important;
		border-top-color: transparent !important;
		display: block;
		height: 1.4em;
		width: 1.4em;
		position: absolute;
		left: calc(50% - (1.4em / 2));
		top: calc(50% - (1.4em / 2));
		-webkit-transform-origin: center;
		transform-origin: center;
		position: absolute !important;
	}
	&.btn-sm:after {
		height: 1em;
		width: 1em;
		left: calc(50% - (1em / 2));
		top: calc(50% - (1em / 2));
	}
}
.btn-group-sm>.btn-loading.btn:after {
	height: 1em;
	width: 1em;
	left: calc(50% - (1em / 2));
	top: calc(50% - (1em / 2));
}
.btn-loading.btn-secondary:after {
	border-color: #495057;
}
.btn-circle {
	text-align: center;
	padding: 6px 0;
	border-radius: 50%;
	text-align: center;
	i {
		font-size: 0.9rem;
	}
}
.btn-social {
	&.btn-lg {
		padding-left: 61px;
		> :first-child {
			line-height: 45px;
			width: 45px;
			font-size: 1.8em;
		}
	}
	&.btn-sm {
		padding-left: 38px;
		> :first-child {
			line-height: 28px;
			width: 28px;
			font-size: 1.4em;
		}
	}
	&.btn-xs {
		padding-left: 30px;
		> :first-child {
			line-height: 20px;
			width: 20px;
			font-size: 1.2em;
		}
	}
}
.btn-social-icon {
	position: relative;
	padding-left: 44px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 34px;
	width: 34px;
	padding: 0;
	> :first-child {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 40px;
		line-height: 34px;
		font-size: 1.1em;
		text-align: center;
		border-right: 1px solid $black-2;
	}
	&.btn-lg {
		padding-left: 61px;
		> :first-child {
			line-height: 45px;
			width: 45px;
			font-size: 1.8em;
		}
	}
	&.btn-sm {
		padding-left: 38px;
		> :first-child {
			line-height: 28px;
			width: 28px;
			font-size: 1.4em;
		}
	}
	&.btn-xs {
		padding-left: 30px;
		> :first-child {
			line-height: 20px;
			width: 20px;
			font-size: 1.2em;
		}
	}
	> :first-child {
		border: none;
		text-align: center;
		width: 100%;
		color: $white;
	}
	&.btn-lg {
		height: 45px;
		width: 45px;
		padding-left: 0;
		padding-right: 0;
	}
	&.btn-sm {
		height: 30px;
		width: 30px;
		padding-left: 0;
		padding-right: 0;
	}
	&.btn-xs {
		height: 22px;
		width: 22px;
		padding-left: 0;
		padding-right: 0;
	}
}
.btn.focus, .btn:focus {
    box-shadow: none !important; 
}